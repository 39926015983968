import React from 'react';
import styled from 'styled-components';

import Card from '../Card';
import Title from '../Title';
import UserContent from '../UserContent';
import ContentCard, { ContentCardProps } from '../ContentCard/ContentCard';

const StyledContentCard = styled(ContentCard)`
	${Title} {
		margin-top: ${({ theme }) => theme.spacing[2]};
		margin-bottom: ${({ theme }) => theme.spacing[6]};
		font-size: 2rem;
		color: ${({ theme }) => theme.colors.text};
		&:after {
			top: 82%;
		}
	}
`;

export type BigTitleCardProps = Omit<ContentCardProps, 'image'> & {
	central?: boolean;
	className?: string;
	image?: string;
	title?: string;
	text?: string;
	link?: string;
	linkText?: string;
};

const BigTitleCard: React.FC<ContentCardProps> = ({ ...props }) => (
	<StyledContentCard underlineTitle central {...props} />
);

export default BigTitleCard;
