import { graphql, PageProps } from 'gatsby';
import React, { Fragment } from 'react';
import { Fade } from 'react-awesome-reveal';
import {
	headerFragment,
	greyBoxFragment,
	textSectionFragment,
} from '../graphql/fragments';
import {
	Context,
	CentralTextBlock,
	Hero,
	LogoStrip,
	MetaData,
	TeamBlock,
	TitleSection,
	UserContent,
} from '../components';
import HeroBanner from '../components/HeroBanner';
import SpaceOverview from '../components/SpaceOverview';
import ThreeCol from '../components/ThreeCol';
import ContentCard from '../components/ContentCard';
import Cols from '../components/Cols';
import BigTitleCard from '../components/BigTitleCard';
import { faAmericanSignLanguageInterpreting } from '@fortawesome/free-solid-svg-icons';
import Button from '../components/Button';

type Props = PageProps & {
	data: GatsbyTypes.WhoWeAreQuery;
};

const WhoWeAre: React.FC<Props> = ({ data }) => {
	const { title, acf } = data?.whoWeAre;

	return (
		<>
			<MetaData
				title={acf?.meta_title || title}
				description={acf?.meta_description}
			/>

			<HeroBanner
				title={acf.header_title}
				text={acf.header_text}
				linkText="Get in touch"
				linkPopup={true}
				images={[
					{
						image: {
							source_url: acf.header_image.source_url,
						},
						focus: 'center',
					},
				]}
			/>

			<SpaceOverview
				reverse
				title={acf.overview_title}
				image={{
					source_url: acf.overview_image?.source_url,
					alt_text: acf.overview_image?.alt_text,
				}}
				text={acf.overview_text}
			/>

			{!!acf?.teams_teams?.length && (
				<TitleSection
					// padTop={false}
					title={acf.teams_title}
					containerSize="sm"
					color="grey"
					content={
						<Fragment>
							{acf.teams_teams.map(({ title, staff }) => (
								<TeamBlock title={title} team={staff} key={title} />
							))}
						</Fragment>
					}
				/>
			)}
			<TitleSection
				title={'Want to work with us?'}
				containerSize="sm"
				color="grey"
				content={
					<>
						<Button href="/jobs">
							Click here to see our current vacancies
						</Button>
					</>
				}
			/>

			<TitleSection
				title={acf.two_col_title}
				containerSize="sm"
				content={
					<Cols cols={2}>
						{acf.two_col_cards?.map((item) => (
							<Fade style={{ overflow: 'hidden' }} key={item.title}>
								<BigTitleCard
									title={item.title}
									text={item.text}
									link={item.link}
									linkText={item.linkText}
								/>
							</Fade>
						))}
					</Cols>
				}
			/>

			{acf.text_sections?.map((item, i) => (
				<SpaceOverview
					reverse={!!(i % 2)}
					title={item.title}
					image={{
						source_url: item.image?.source_url,
						alt_text: item.image?.alt_text,
					}}
					text={item.text}
					key={item.title}
				/>
			))}
		</>
	);

	// return (
	// 	<Context.Consumer>
	// 		{({ setModal }) => {
	// 			return (
	// 				<Fragment>
	// 					<MetaData
	// 						title={acf.meta_title || title}
	// 						desciption={acf.meta_description}
	// 					/>

	// 					<Hero
	// 						images={acf.header.header_images}
	// 						title={acf.header.header_title}
	// 						logo={
	// 							acf.header.header_logo &&
	// 							acf.header.header_logo.source_url
	// 						}
	// 						link={acf.header.header_link}
	// 						linkText={acf.header.header_link_text}
	// 						linkPopup={acf.header.header_link_popup}
	// 					/>

	// 					<CentralTextBlock
	// 						title={acf.grey_box.title}
	// 						text={acf.grey_box.text}
	// 						linkPopup={acf.grey_box.link_popup}
	// 						link={acf.grey_box.link}
	// 						linkText={acf.grey_box.link_text}
	// 					/>

	// 					<LogoStrip logos={acf.about_logos.logos} />

	// 					{acf.text_section.content && (
	// 						<TitleSection
	// 							title={acf.text_section.title}
	// 							color={"grey"}
	// 							containerSize='sm'
	// 							content={
	// 								<Fragment>
	// 									{acf.text_section.images &&
	// 										!!acf.text_section.images
	// 											.length && (
	// 											<Fade>
	// 												<div className='our-story-image-row w-row'>
	// 													{acf.text_section.images.map(
	// 														({
	// 															source_url,
	// 															alt_text,
	// 														}) => (
	// 															<div
	// 																className='our-story-image-row-col1 w-col w-col-3'
	// 																key={`tex-section-${source_url}`}
	// 															>
	// 																<div className='our-story-img-container'>
	// 																	<img
	// 																		src={
	// 																			source_url
	// 																		}
	// 																		alt={
	// 																			alt_text
	// 																		}
	// 																	/>
	// 																</div>
	// 															</div>
	// 														)
	// 													)}
	// 												</div>
	// 											</Fade>
	// 										)}

	// 									<Fade>
	// 										<UserContent
	// 											content={
	// 												acf.text_section.content
	// 											}
	// 										/>
	// 									</Fade>

	// 									{acf.text_section.link_text ? (
	// 										<div className='button-wrapper-bottom'>
	// 											{acf.text_section.popup_link ? (
	// 												<Button
	// 													onClick={
	// 														toggleModalOpen
	// 													}
	// 												>
	// 													{
	// 														acf.text_section
	// 															.link_text
	// 													}
	// 												</Button>
	// 											) : (
	// 												<Button
	// 													href={
	// 														acf.text_section
	// 															.link
	// 													}
	// 												>
	// 													{
	// 														acf.text_section
	// 															.link_text
	// 													}
	// 												</Button>
	// 											)}
	// 										</div>
	// 									) : null}
	// 								</Fragment>
	// 							}
	// 						/>
	// 					)}

	// 					{teamStaff && !!teamStaff.length && (
	// 						<TitleSection
	// 							padTop={false}
	// 							title={acf.teams_title}
	// 							color={"white"}
	// 							containerSize='sm'
	// 							content={
	// 								<Fragment>
	// 									{teamStaff.map(({ title, staff }) => (
	// 										<TeamBlock
	// 											title={title}
	// 											team={staff}
	// 											key={title}
	// 										/>
	// 									))}
	// 								</Fragment>
	// 							}
	// 						/>
	// 					)}
	// 				</Fragment>
	// 			);
	// 		}}
	// 	</Context.Consumer>
	// );
};

export const query = graphql`
	fragment Header on wordpress__PAGEAcf {
		header {
			header_title
			header_images {
				focus
				image {
					source_url
				}
			}
			header_logo {
				source_url
			}
			header_link
			header_link_popup
			header_link_text
		}
	}

	fragment GreyBox on wordpress__PAGEAcf {
		grey_box {
			title
			text
			link
			link_text
			link_popup
		}
	}

	fragment TextSection on wordpress__PAGEAcf {
		text_section {
			title
			images {
				source_url
				alt_text
			}
			content
		}
	}
`;

export const pageQuery = graphql`
	query WhoWeArePage {
		whoWeAre: wordpressPage(slug: { eq: "who-we-are" }) {
			title
			acf {
				header_title
				header_text
				header_image {
					alt_text
					source_url
				}
				overview_title
				overview_text
				overview_image {
					source_url
					alt_text
				}
				teams_title
				teams_teams {
					title
					staff {
						wordpress_id
					}
				}

				two_col_title
				two_col_cards {
					title
					text
					link
					link_text
				}
				text_sections {
					title
					text
					image {
						source_url
						alt_text
					}
				}
				meta_title
				meta_description
			}
		}
	}
`;

export default WhoWeAre;
