import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

type ColsProps = {
	cols: 2 | 3 | 4;
};

const Cols = styled.div<ColsProps>`
	--gutter: ${({ theme }) => theme.spacing.gutter};
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: calc(-1 * var(--gutter) / 2);
	margin-bottom: 0;

	> * {
		width: ${({ theme, cols }) =>
			`calc(((100% - (3 * var(--gutter))) / ${cols}))`};
		margin: calc(var(--gutter) / 2);
	}

	.react-reveal {
		display: flex;
		justify-content: center;
		> * {
			width: 100%;
		}
	}

	@media ${breakpoints.mobileLand} {
		> * {
			width: calc(50% - (var(--gutter) * 2));
		}
	}

	@media ${breakpoints.mobilePort} {
		> * {
			width: 100%;
		}
	}
`;

export default Cols;
